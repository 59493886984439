import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/business-service/response";
import * as BusinessServiceAPI from "@/api/business-service";
import { isSuccess } from "@/api/response";
import { GetRequest } from "@/api/business-service/request";

const MODULE_NAME = "business-service/get";

/**
 * サービス事業者取得API（/get-business-service）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResults() {
    return this.getResponse.results;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await BusinessServiceAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

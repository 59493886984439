import { Component, Vue } from "vue-property-decorator";
import Admin from "@/store/admin/admin";
import Loading from "@/store/loading";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { LoginRequest } from "@/api/admin/request";
import { MenuStorage } from "@/store/menu-storage";
import BusinessService from "@/store/business-service/get";
import { BusinessServiceStorage } from "@/store/business-service-storage";
import { GetRequest as BusinessServiceRequest } from "@/api/business-service/request";

@Component
export default class index extends Vue {
  // ログイン画面の表示・非表示（ログイン直後のちらつき防止）
  showView = true;

  // ログインID
  loginId = null as string | null;

  // ログインパスワード
  password = null as string | null;

  // ログイン状態の保持
  remember = false as boolean;

  // ロゴ画像
  logoImagePath = null as string | null;

  //FAQ表示タイミング用フラグ
  businessId = 0 as number;

  get loading() {
    return Loading.isLoading;
  }

  get AdminTool() {
    let userAgent = window.navigator.userAgent;
    if (userAgent.match(/newup_admin_tool_ios/) || userAgent.match(/newup_admin_tool_android/)) {
      return true;
    }

    return false;
  }

  async beforeDestroy() {
    await BusinessService.clear();
  }

  async mounted() {
    await Admin.clear();
    // サービス事業者取得
    const businessServiceRequest = this.createBusinessServiceRequest();
    await BusinessService.get(businessServiceRequest);
    if (BusinessService.isSuccess) {
      // サービス事業者情報をセッションストレージに設定
      BusinessServiceStorage.setSessionStorage(BusinessService.getResults);
      this.logoImagePath = BusinessService.getResults.logoImagePath;
      this.businessId = BusinessService.getResults.id;
    } else {
      BusinessServiceStorage.removeSessionStorage();
      await Flash.setErrorNow({
        message: BusinessService.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }
  /**
   * ログインボタンのイベントハンドラー
   */
  async loggedIn() {
    await Flash.clear();
    const loginRequest = this.createRequest();
    await Admin.login(loginRequest);
    if (Admin.isSuccess) {
      // 管理ツールアプリ対応
      if (this.AdminTool) {
        let result = MenuStorage.getMenuResult();
        alert(JSON.stringify({
          COOKIE_ADMIN_SHOP_ID: result.shopId,
          COOKIE_ADMIN_MAIN_SHOP_ID: result.mainShopId,
          COOKIE_ADMIN_USE_SHOP_ID: result.useShopId,
          COOKIE_ADMIN_USE_SHOP_NAME: result.useShopName,
          KEY: result.appApiKey,
          _vue_param: true
        }));
      }

      // サービス事業者情報をローカルストレージに設定
      BusinessServiceStorage.setLocalStorage(BusinessServiceStorage.getSessionStorage());
      this.showView = false;
      await this.$router.push({ name: "home" });
    } else {
      await Flash.setErrorNow({
        message: Admin.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * ログインAPIのリクエストを作成
   */
  private createRequest(): LoginRequest {
    const storageData = BusinessServiceStorage.getSessionStorage();
    return {
      loginId: this.loginId,
      password: this.password,
      businessId: storageData ? storageData.id : "",
      remember: this.AdminTool ? true : this.remember
    } as LoginRequest;
  }

  private createBusinessServiceRequest(): BusinessServiceRequest {
    const searchParams = new URLSearchParams(location.search);
    let businessCode = searchParams.get("business_code");
    if (!businessCode) {
      let storageData = BusinessServiceStorage.getSessionStorage();
      businessCode = Object.keys(storageData).length ? storageData.code : this.getBusinessCodeByDomain();
    }
    return {
      businessCode: businessCode
    } as BusinessServiceRequest;
  }
  

  /**
   * 現在のURLのドメインをもとに事業者コードを取得するメソッド
   * @returns 
   */
  private getBusinessCodeByDomain(): String {
    const domainMap: { [key: string]: string } = { 'localhost': 'usen', 'vn.uplink-app.com': 'usen', 'liglly-app': 'liglly' }
    let businessCode = "" as string | null;

    for (const key in domainMap) {
      if(location.hostname.match(key)){
        businessCode = domainMap[key];
        break;
      }
    }

    return businessCode as String;
  }

}

import service from "@/api/service";
import { GetResponse } from "@/api/business-service/response";
import { GetRequest } from "@/api/business-service/request";

/**
 * サービス事業者取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-business-service", getRequest);
  return response.data as GetResponse;
}
